import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'

const SecondPage = () => (
  <Layout>
    <h1>Hello</h1>
    <p>I just build this website for <strong>181018</strong> date. Right now I don't have any idea about the design or whatsoever. Feel free to contact me at 
      <a href="https://afiq.me/contact" target="_blank" rel="noopener noreferrer"> https://afiq.me/contact</a>. Ciao!
    </p>
    {/* <Link to="/">Go back to the homepage</Link> */}
  </Layout>
)

export default SecondPage
