import React from 'react'
import { Link } from 'gatsby'

const Header = ({ siteTitle }) => (
  <div
    style={{
      background: 'white',
      marginBottom: '1.45rem',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,.1)',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 auto',
        maxWidth: 960,
        padding: '.7rem 1.0875rem 1rem',
        // boxShadow: '0 4px 8px 0 rgba(0,0,0,.1)',
      }}
    >
      <h1 style={{ margin: 0, fontSize: '1.25rem', }}>
        <Link
          to="/"
          style={{
            color: '#505050',
            textDecoration: 'none',
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <Link to="/about/">About</Link>
    </div>
  </div>
)

export default Header
